import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Typography, 
  Box, 
  Grid, 
  Card, 
  CardContent, 
  CardMedia,
  Button,
  Chip,
  Divider,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Breadcrumbs,
  Link,
  Alert
} from '@mui/material';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import HomeIcon from '@mui/icons-material/Home';
import EuroIcon from '@mui/icons-material/Euro';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import BedIcon from '@mui/icons-material/Bed';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import ShareIcon from '@mui/icons-material/Share';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

// Données simulées pour la démonstration
const mockProperties = Array.from({ length: 24 }, (_, i) => ({
  id: i + 1,
  title: `Bien immobilier ${i + 1}`,
  description: `Description détaillée du bien immobilier ${i + 1}. Situé dans un quartier calme et recherché, proche des commerces et transports. Ce logement bénéficie d'une excellente exposition et d'une vue dégagée. Les pièces sont spacieuses et lumineuses, avec de beaux volumes. La cuisine est entièrement équipée et le salon donne sur un balcon agréable. L'immeuble est bien entretenu et sécurisé.`,
  price: Math.floor(Math.random() * 900000) + 100000,
  surface: Math.floor(Math.random() * 150) + 20,
  rooms: Math.floor(Math.random() * 5) + 1,
  bedrooms: Math.floor(Math.random() * 4) + 1,
  bathrooms: Math.floor(Math.random() * 3) + 1,
  location: ['Paris', 'Lyon', 'Marseille', 'Bordeaux', 'Lille', 'Toulouse', 'Nantes'][Math.floor(Math.random() * 7)],
  postalCode: ['75001', '69001', '13001', '33000', '59000', '31000', '44000'][Math.floor(Math.random() * 7)],
  address: `${Math.floor(Math.random() * 100) + 1} rue des ${['Fleurs', 'Lilas', 'Roses', 'Chênes', 'Ormes', 'Platanes', 'Tilleuls'][Math.floor(Math.random() * 7)]}`,
  propertyType: ['Appartement', 'Maison', 'Studio', 'Loft'][Math.floor(Math.random() * 4)],
  transactionType: Math.random() > 0.3 ? 'Vente' : 'Location',
  source: ['BienIci', 'ParuVendu', 'LeSiteImmo', 'EtreProprio', 'Immonot', 'ImmoRegion', 'OuestImmo'][Math.floor(Math.random() * 7)],
  sourceUrl: 'https://example.com/annonce',
  features: [
    'Ascenseur',
    'Balcon',
    'Parking',
    'Cave',
    'Gardien',
    'Interphone',
    'Terrasse',
    'Jardin',
    'Piscine',
    'Climatisation'
  ].sort(() => 0.5 - Math.random()).slice(0, Math.floor(Math.random() * 6) + 2),
  energyClass: ['A', 'B', 'C', 'D', 'E', 'F', 'G'][Math.floor(Math.random() * 7)],
  gasEmissionClass: ['A', 'B', 'C', 'D', 'E', 'F', 'G'][Math.floor(Math.random() * 7)],
  images: Array.from({ length: Math.floor(Math.random() * 5) + 3 }, (_, j) => 
    `https://source.unsplash.com/random/800x600/?house&sig=${i}_${j}`
  ),
  createdAt: new Date(Date.now() - Math.floor(Math.random() * 30) * 24 * 60 * 60 * 1000).toISOString(),
}));

const PropertyDetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [property, setProperty] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [mainImage, setMainImage] = useState('');
  const [isFavorite, setIsFavorite] = useState(false);

  // Simuler le chargement des données de la propriété
  useEffect(() => {
    // Dans une application réelle, cela serait un appel API
    const fetchProperty = () => {
      setLoading(true);
      try {
        const foundProperty = mockProperties.find(p => p.id === parseInt(id));
        if (foundProperty) {
          setProperty(foundProperty);
          setMainImage(foundProperty.images[0]);
        } else {
          setError("Propriété non trouvée");
        }
      } catch (err) {
        setError("Erreur lors du chargement des données");
      } finally {
        setLoading(false);
      }
    };

    fetchProperty();
  }, [id]);

  // Formatage du prix
  const formatPrice = (price) => {
    return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(price);
  };

  // Formatage de la date
  const formatDate = (dateString) => {
    const options = { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric'
    };
    return new Date(dateString).toLocaleDateString('fr-FR', options);
  };

  // Gestionnaire pour changer l'image principale
  const handleImageChange = (imageUrl) => {
    setMainImage(imageUrl);
  };

  // Gestionnaire pour ajouter/retirer des favoris
  const handleToggleFavorite = () => {
    setIsFavorite(!isFavorite);
  };

  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ py: 4, textAlign: 'center' }}>
        <Typography variant="h5">Chargement des détails de la propriété...</Typography>
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Alert severity="error" sx={{ mb: 3 }}>{error}</Alert>
        <Button 
          variant="contained" 
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate('/recherche')}
        >
          Retour à la recherche
        </Button>
      </Container>
    );
  }

  if (!property) {
    return (
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Alert severity="warning" sx={{ mb: 3 }}>Propriété non trouvée</Alert>
        <Button 
          variant="contained" 
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate('/recherche')}
        >
          Retour à la recherche
        </Button>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      {/* Fil d'Ariane */}
      <Breadcrumbs sx={{ mb: 3 }}>
        <Link component={RouterLink} to="/" underline="hover" color="inherit">
          Accueil
        </Link>
        <Link component={RouterLink} to="/recherche" underline="hover" color="inherit">
          Recherche
        </Link>
        <Typography color="text.primary">{property.title}</Typography>
      </Breadcrumbs>
      
      {/* Titre et actions */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 3 }}>
        <Box>
          <Typography variant="h4" component="h1" gutterBottom>
            {property.title}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <LocationOnIcon fontSize="small" color="action" sx={{ mr: 0.5 }} />
            <Typography variant="subtitle1" color="text.secondary">
              {property.address}, {property.postalCode} {property.location}
            </Typography>
          </Box>
        </Box>
        
        <Box sx={{ display: 'flex', gap: 1 }}>
          <IconButton 
            aria-label="ajouter aux favoris" 
            onClick={handleToggleFavorite}
            sx={{ 
              color: isFavorite ? 'error.main' : 'action.active',
              border: 1,
              borderColor: 'divider'
            }}
          >
            {isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
          </IconButton>
          <IconButton 
            aria-label="partager" 
            sx={{ 
              color: 'action.active',
              border: 1,
              borderColor: 'divider'
            }}
          >
            <ShareIcon />
          </IconButton>
          <Button 
            variant="contained" 
            startIcon={<NotificationsActiveIcon />}
            component={RouterLink}
            to={`/creer-alerte?type=${property.propertyType}&location=${property.location}&price=${property.price}&surface=${property.surface}&rooms=${property.rooms}`}
          >
            Créer une alerte similaire
          </Button>
        </Box>
      </Box>
      
      {/* Informations principales et galerie d'images */}
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          {/* Image principale */}
          <Box 
            sx={{ 
              width: '100%', 
              height: 400, 
              backgroundImage: `url(${mainImage})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              borderRadius: 2,
              mb: 2
            }} 
          />
          
          {/* Galerie d'images miniatures */}
          <Box sx={{ display: 'flex', gap: 1, overflowX: 'auto', pb: 1 }}>
            {property.images.map((image, index) => (
              <Box 
                key={index}
                onClick={() => handleImageChange(image)}
                sx={{ 
                  width: 100, 
                  height: 75, 
                  backgroundImage: `url(${image})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  borderRadius: 1,
                  cursor: 'pointer',
                  border: mainImage === image ? '2px solid' : '1px solid',
                  borderColor: mainImage === image ? 'primary.main' : 'divider',
                  opacity: mainImage === image ? 1 : 0.7,
                  '&:hover': {
                    opacity: 1
                  }
                }} 
              />
            ))}
          </Box>
        </Grid>
        
        <Grid item xs={12} md={4}>
          <Card elevation={3}>
            <CardContent>
              <Typography variant="h4" color="primary" fontWeight="bold" gutterBottom>
                {formatPrice(property.price)}
              </Typography>
              
              <Chip 
                label={property.transactionType} 
                color="primary" 
                sx={{ mb: 2 }} 
              />
              
              <Divider sx={{ my: 2 }} />
              
              <List disablePadding>
                <ListItem disableGutters>
                  <ListItemIcon sx={{ minWidth: 40 }}>
                    <HomeIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText 
                    primary={property.propertyType} 
                    secondary="Type de bien" 
                  />
                </ListItem>
                
                <ListItem disableGutters>
                  <ListItemIcon sx={{ minWidth: 40 }}>
                    <SquareFootIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText 
                    primary={`${property.surface} m²`} 
                    secondary="Surface" 
                  />
                </ListItem>
                
                <ListItem disableGutters>
                  <ListItemIcon sx={{ minWidth: 40 }}>
                    <BedIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText 
                    primary={`${property.rooms} pièce${property.rooms > 1 ? 's' : ''} (${property.bedrooms} chambre${property.bedrooms > 1 ? 's' : ''})`} 
                    secondary="Pièces" 
                  />
                </ListItem>
                
                <ListItem disableGutters>
                  <ListItemIcon sx={{ minWidth: 40 }}>
                    <CalendarTodayIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText 
                    primary={formatDate(property.createdAt)} 
                    secondary="Date de publication" 
                  />
                </ListItem>
              </List>
              
              <Divider sx={{ my: 2 }} />
              
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Typography variant="subtitle1" sx={{ mr: 1 }}>
                  Source:
                </Typography>
                <Chip 
                  label={property.source} 
                  size="small" 
                  color="secondary"
                  onClick={() => window.open(property.sourceUrl, '_blank')}
                  onDelete={() => window.open(property.sourceUrl, '_blank')}
                  deleteIcon={<OpenInNewIcon />}
                />
              </Box>
              
              <Button 
                variant="contained" 
                color="primary" 
                fullWidth
                onClick={() => window.open(property.sourceUrl, '_blank')}
                endIcon={<OpenInNewIcon />}
              >
                Voir l'annonce originale
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      
      {/* Description et caractéristiques */}
      <Grid container spacing={3} sx={{ mt: 2 }}>
        <Grid item xs={12} md={8}>
          <Paper elevation={2} sx={{ p: 3, mb: 3 }}>
            <Typography variant="h5" gutterBottom>
              Description
            </Typography>
            <Typography variant="body1" paragraph>
              {property.description}
            </Typography>
          </Paper>
          
          <Paper elevation={2} sx={{ p: 3 }}>
            <Typography variant="h5" gutterBottom>
              Caractéristiques
            </Typography>
            <Grid container spacing={2}>
              {property.features.map((feature, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <CheckCircleOutlineIcon color="primary" sx={{ mr: 1 }} />
                    <Typography variant="body1">{feature}</Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Grid>
        
        <Grid item xs={12} md={4}>
          <Paper elevation={2} sx={{ p: 3, mb: 3 }}>
            <Typography variant="h5" gutterBottom>
              Diagnostic énergétique
            </Typography>
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle1" gutterBottom>
                Classe énergie
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Chip 
                  label={property.energyClass} 
                  color={
                    property.energyClass === 'A' || property.energyClass === 'B' ? 'success' :
                    property.energyClass === 'C' || property.energyClass === 'D' ? 'warning' : 'error'
                  }
                  sx={{ mr: 1, fontWeight: 'bold' }}
                />
                <Typography variant="body2" color="text.secondary">
                  {property.energyClass === 'A' || property.energyClass === 'B' ? 'Économe' :
                   property.energyClass === 'C' || property.energyClass === 'D' ? 'Moyenne' : 'Énergivore'}
                </Typography>
              </Box>
            </Box>
            
            <Box>
              <Typography variant="subtitle1" gutterBottom>
                Émissions GES
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Chip 
                  label={property.gasEmissionClass} 
                  color={
                    property.gasEmissionClass === 'A' || property.gasEmissionClass === 'B' ? 'success' :
                    property.gasEmissionClass === 'C' || property.gasEmissionClass === 'D' ? 'warning' : 'error'
                  }
                  sx={{ mr: 1, fontWeight: 'bold' }}
                />
                <Typography variant="body2" color="text.secondary">
                  {property.gasEmissionClass === 'A' || property.gasEmissionClass === 'B' ? 'Faible' :
                   property.gasEmissionClass === 'C' || property.gasEmissionClass === 'D' ? 'Moyenne' : 'Élevée'}
                </Typography>
              </Box>
            </Box>
          </Paper>
          
          <Paper elevation={2} sx={{ p: 3 }}>
            <Typography variant="h5" gutterBottom>
              Biens similaires
            </Typography>
            <Button 
              variant="outlined" 
              fullWidth
              component={RouterLink}
              to={`/recherche?type=${property.propertyType}&location=${property.location}`}
              sx={{ mb: 2 }}
            >
              Voir les biens similaires
            </Button>
            <Button 
              variant="contained" 
              color="secondary"
              fullWidth
              startIcon={<NotificationsActiveIcon />}
              component={RouterLink}
              to={`/creer-alerte?type=${property.propertyType}&location=${property.location}&price=${property.price}&surface=${property.surface}&rooms=${property.rooms}`}
            >
              Créer une alerte
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PropertyDetailPage;
