import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Typography, 
  Box, 
  Grid, 
  Card, 
  CardContent, 
  Button,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Slider,
  FormControlLabel,
  Switch,
  Divider,
  Alert,
  Snackbar,
  Paper,
  InputAdornment
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import SaveIcon from '@mui/icons-material/Save';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EuroIcon from '@mui/icons-material/Euro';
import SquareFootIcon from '@mui/icons-material/SquareFoot';

const CreateAlertPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  
  // État pour le formulaire
  const [alertData, setAlertData] = useState({
    name: '',
    transactionType: 'Vente',
    propertyType: '',
    location: '',
    priceRange: [0, 1000000],
    surfaceRange: [0, 200],
    minRooms: '',
    frequency: 'real-time',
    active: true
  });
  
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isEditMode, setIsEditMode] = useState(false);
  
  // Récupérer les paramètres de l'URL pour pré-remplir le formulaire
  useEffect(() => {
    // Vérifier si on est en mode édition
    const editId = queryParams.get('edit');
    if (editId) {
      setIsEditMode(true);
      // Dans une application réelle, on ferait un appel API pour récupérer les données de l'alerte
      // Ici, on simule avec des données fictives
      setAlertData({
        id: parseInt(editId),
        name: "Appartement à Paris",
        transactionType: "Vente",
        propertyType: "Appartement",
        location: "Paris",
        priceRange: [200000, 500000],
        surfaceRange: [40, 80],
        minRooms: "2",
        frequency: "real-time",
        active: true
      });
    } else {
      // Récupérer les paramètres de recherche pour pré-remplir le formulaire
      const type = queryParams.get('type');
      const location = queryParams.get('location');
      const price = queryParams.get('price');
      const surface = queryParams.get('surface');
      const rooms = queryParams.get('rooms');
      
      if (type || location || price || surface || rooms) {
        const newAlertData = { ...alertData };
        
        if (type) newAlertData.propertyType = type;
        if (location) {
          newAlertData.location = location;
          newAlertData.name = `${type || 'Bien'} à ${location}`;
        }
        if (price) {
          const minPrice = Math.max(0, parseInt(price) * 0.8);
          const maxPrice = parseInt(price) * 1.2;
          newAlertData.priceRange = [minPrice, maxPrice];
        }
        if (surface) {
          const minSurface = Math.max(0, parseInt(surface) * 0.8);
          const maxSurface = parseInt(surface) * 1.2;
          newAlertData.surfaceRange = [minSurface, maxSurface];
        }
        if (rooms) newAlertData.minRooms = rooms;
        
        setAlertData(newAlertData);
      }
    }
  }, [location.search]);
  
  // Gestionnaire de changement pour les champs simples
  const handleChange = (e) => {
    const { name, value } = e.target;
    setAlertData({
      ...alertData,
      [name]: value
    });
  };
  
  // Gestionnaire pour le switch d'activation
  const handleSwitchChange = (e) => {
    setAlertData({
      ...alertData,
      active: e.target.checked
    });
  };
  
  // Gestionnaire pour la soumission du formulaire
  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Validation basique
    if (!alertData.name.trim()) {
      setSnackbarMessage("Veuillez donner un nom à votre alerte");
      setSnackbarOpen(true);
      return;
    }
    
    if (!alertData.location.trim()) {
      setSnackbarMessage("Veuillez spécifier une localisation");
      setSnackbarOpen(true);
      return;
    }
    
    // Dans une application réelle, on enverrait les données à l'API
    console.log("Données de l'alerte à envoyer:", alertData);
    
    // Afficher un message de succès
    setSnackbarMessage(isEditMode ? "Alerte mise à jour avec succès" : "Alerte créée avec succès");
    setSnackbarOpen(true);
    
    // Rediriger vers la page des alertes après un court délai
    setTimeout(() => {
      navigate('/alertes');
    }, 1500);
  };
  
  // Formatage du prix
  const formatPrice = (price) => {
    return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(price);
  };
  
  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        {isEditMode ? "Modifier l'alerte" : "Créer une nouvelle alerte"}
      </Typography>
      
      <Paper elevation={3} sx={{ p: 4, mt: 3 }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Nom de l'alerte"
                name="name"
                value={alertData.name}
                onChange={handleChange}
                placeholder="Ex: Appartement 3 pièces à Paris"
                required
              />
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="transaction-type-label">Type de transaction</InputLabel>
                <Select
                  labelId="transaction-type-label"
                  name="transactionType"
                  value={alertData.transactionType}
                  label="Type de transaction"
                  onChange={handleChange}
                >
                  <MenuItem value="Vente">Vente</MenuItem>
                  <MenuItem value="Location">Location</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="property-type-label">Type de bien</InputLabel>
                <Select
                  labelId="property-type-label"
                  name="propertyType"
                  value={alertData.propertyType}
                  label="Type de bien"
                  onChange={handleChange}
                >
                  <MenuItem value="">Tous</MenuItem>
                  <MenuItem value="Appartement">Appartement</MenuItem>
                  <MenuItem value="Maison">Maison</MenuItem>
                  <MenuItem value="Studio">Studio</MenuItem>
                  <MenuItem value="Loft">Loft</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Localisation"
                name="location"
                value={alertData.location}
                onChange={handleChange}
                placeholder="Ville ou code postal"
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOnIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            
            <Grid item xs={12}>
              <Typography gutterBottom>
                Prix ({formatPrice(alertData.priceRange[0])} - {formatPrice(alertData.priceRange[1])})
              </Typography>
              <Slider
                value={alertData.priceRange}
                onChange={(e, newValue) => setAlertData({...alertData, priceRange: newValue})}
                valueLabelDisplay="auto"
                min={0}
                max={1000000}
                step={10000}
                valueLabelFormat={(value) => formatPrice(value)}
              />
            </Grid>
            
            <Grid item xs={12}>
              <Typography gutterBottom>
                Surface ({alertData.surfaceRange[0]} - {alertData.surfaceRange[1]} m²)
              </Typography>
              <Slider
                value={alertData.surfaceRange}
                onChange={(e, newValue) => setAlertData({...alertData, surfaceRange: newValue})}
                valueLabelDisplay="auto"
                min={0}
                max={200}
                step={5}
                valueLabelFormat={(value) => `${value} m²`}
              />
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="min-rooms-label">Pièces (minimum)</InputLabel>
                <Select
                  labelId="min-rooms-label"
                  name="minRooms"
                  value={alertData.minRooms}
                  label="Pièces (minimum)"
                  onChange={handleChange}
                >
                  <MenuItem value="">Indifférent</MenuItem>
                  <MenuItem value="1">1+</MenuItem>
                  <MenuItem value="2">2+</MenuItem>
                  <MenuItem value="3">3+</MenuItem>
                  <MenuItem value="4">4+</MenuItem>
                  <MenuItem value="5">5+</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="frequency-label">Fréquence des alertes</InputLabel>
                <Select
                  labelId="frequency-label"
                  name="frequency"
                  value={alertData.frequency}
                  label="Fréquence des alertes"
                  onChange={handleChange}
                >
                  <MenuItem value="real-time">Temps réel</MenuItem>
                  <MenuItem value="daily">Quotidienne</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={12}>
              <Divider sx={{ my: 1 }} />
              <FormControlLabel
                control={
                  <Switch 
                    checked={alertData.active} 
                    onChange={handleSwitchChange}
                    color="primary"
                  />
                }
                label="Activer l'alerte immédiatement"
              />
            </Grid>
            
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
              <Button 
                variant="outlined" 
                onClick={() => navigate('/alertes')}
              >
                Annuler
              </Button>
              <Button 
                type="submit"
                variant="contained" 
                color="primary"
                startIcon={<SaveIcon />}
              >
                {isEditMode ? "Mettre à jour" : "Créer l'alerte"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
      
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setSnackbarOpen(false)} 
          severity={snackbarMessage.includes("Veuillez") ? "error" : "success"} 
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default CreateAlertPage;
