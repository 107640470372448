import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Typography, 
  Box, 
  Grid, 
  Card, 
  CardContent, 
  CardMedia, 
  CardActions,
  Button,
  TextField,
  MenuItem,
  Slider,
  FormControl,
  InputLabel,
  Select,
  Pagination,
  Chip,
  Divider,
  Paper,
  IconButton,
  InputAdornment
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import HomeIcon from '@mui/icons-material/Home';
import EuroIcon from '@mui/icons-material/Euro';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import BedIcon from '@mui/icons-material/Bed';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import NotificationsIcon from '@mui/icons-material/Notifications';

// Données simulées pour la démonstration
const mockProperties = Array.from({ length: 24 }, (_, i) => ({
  id: i + 1,
  title: `Bien immobilier ${i + 1}`,
  description: `Description du bien immobilier ${i + 1}. Situé dans un quartier calme et recherché, proche des commerces et transports.`,
  price: Math.floor(Math.random() * 900000) + 100000,
  surface: Math.floor(Math.random() * 150) + 20,
  rooms: Math.floor(Math.random() * 5) + 1,
  bedrooms: Math.floor(Math.random() * 4) + 1,
  location: ['Paris', 'Lyon', 'Marseille', 'Bordeaux', 'Lille', 'Toulouse', 'Nantes'][Math.floor(Math.random() * 7)],
  postalCode: ['75001', '69001', '13001', '33000', '59000', '31000', '44000'][Math.floor(Math.random() * 7)],
  propertyType: ['Appartement', 'Maison', 'Studio', 'Loft'][Math.floor(Math.random() * 4)],
  transactionType: Math.random() > 0.3 ? 'Vente' : 'Location',
  source: ['BienIci', 'ParuVendu', 'LeSiteImmo', 'EtreProprio', 'Immonot', 'ImmoRegion', 'OuestImmo'][Math.floor(Math.random() * 7)],
  imageUrl: `https://source.unsplash.com/random/400x300/?house&sig=${i}`,
  createdAt: new Date(Date.now() - Math.floor(Math.random() * 30) * 24 * 60 * 60 * 1000).toISOString(),
}));

const SearchPage = () => {
  // États pour les filtres
  const [transactionType, setTransactionType] = useState('Vente');
  const [propertyType, setPropertyType] = useState('');
  const [location, setLocation] = useState('');
  const [priceRange, setPriceRange] = useState([0, 1000000]);
  const [surfaceRange, setSurfaceRange] = useState([0, 200]);
  const [rooms, setRooms] = useState('');
  const [page, setPage] = useState(1);
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [sortBy, setSortBy] = useState('date');

  const propertiesPerPage = 9;

  // Effet pour filtrer les propriétés
  useEffect(() => {
    let filtered = [...mockProperties];
    
    // Appliquer les filtres
    if (transactionType) {
      filtered = filtered.filter(prop => prop.transactionType === transactionType);
    }
    
    if (propertyType) {
      filtered = filtered.filter(prop => prop.propertyType === propertyType);
    }
    
    if (location) {
      filtered = filtered.filter(prop => 
        prop.location.toLowerCase().includes(location.toLowerCase()) || 
        prop.postalCode.includes(location)
      );
    }
    
    filtered = filtered.filter(prop => 
      prop.price >= priceRange[0] && 
      prop.price <= priceRange[1]
    );
    
    filtered = filtered.filter(prop => 
      prop.surface >= surfaceRange[0] && 
      prop.surface <= surfaceRange[1]
    );
    
    if (rooms) {
      filtered = filtered.filter(prop => prop.rooms >= parseInt(rooms));
    }
    
    // Appliquer le tri
    if (sortBy === 'price_asc') {
      filtered.sort((a, b) => a.price - b.price);
    } else if (sortBy === 'price_desc') {
      filtered.sort((a, b) => b.price - a.price);
    } else if (sortBy === 'surface_asc') {
      filtered.sort((a, b) => a.surface - b.surface);
    } else if (sortBy === 'surface_desc') {
      filtered.sort((a, b) => b.surface - a.surface);
    } else if (sortBy === 'date') {
      filtered.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    }
    
    setFilteredProperties(filtered);
  }, [transactionType, propertyType, location, priceRange, surfaceRange, rooms, sortBy]);

  // Calcul des propriétés à afficher pour la pagination
  const displayedProperties = filteredProperties.slice(
    (page - 1) * propertiesPerPage,
    page * propertiesPerPage
  );

  // Gestionnaire de changement de page
  const handlePageChange = (event, value) => {
    setPage(value);
    window.scrollTo(0, 0);
  };

  // Formatage du prix
  const formatPrice = (price) => {
    return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(price);
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Recherche immobilière
      </Typography>
      
      {/* Filtres */}
      <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth>
              <InputLabel id="transaction-type-label">Type de transaction</InputLabel>
              <Select
                labelId="transaction-type-label"
                value={transactionType}
                label="Type de transaction"
                onChange={(e) => setTransactionType(e.target.value)}
              >
                <MenuItem value="Vente">Vente</MenuItem>
                <MenuItem value="Location">Location</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth>
              <InputLabel id="property-type-label">Type de bien</InputLabel>
              <Select
                labelId="property-type-label"
                value={propertyType}
                label="Type de bien"
                onChange={(e) => setPropertyType(e.target.value)}
              >
                <MenuItem value="">Tous</MenuItem>
                <MenuItem value="Appartement">Appartement</MenuItem>
                <MenuItem value="Maison">Maison</MenuItem>
                <MenuItem value="Studio">Studio</MenuItem>
                <MenuItem value="Loft">Loft</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              label="Localisation"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              placeholder="Ville ou code postal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LocationOnIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth>
              <InputLabel id="rooms-label">Pièces (min)</InputLabel>
              <Select
                labelId="rooms-label"
                value={rooms}
                label="Pièces (min)"
                onChange={(e) => setRooms(e.target.value)}
              >
                <MenuItem value="">Indifférent</MenuItem>
                <MenuItem value="1">1+</MenuItem>
                <MenuItem value="2">2+</MenuItem>
                <MenuItem value="3">3+</MenuItem>
                <MenuItem value="4">4+</MenuItem>
                <MenuItem value="5">5+</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          
          <Grid item xs={12} sm={6}>
            <Typography gutterBottom>
              Prix ({formatPrice(priceRange[0])} - {formatPrice(priceRange[1])})
            </Typography>
            <Slider
              value={priceRange}
              onChange={(e, newValue) => setPriceRange(newValue)}
              valueLabelDisplay="auto"
              min={0}
              max={1000000}
              step={10000}
              valueLabelFormat={(value) => formatPrice(value)}
            />
          </Grid>
          
          <Grid item xs={12} sm={6}>
            <Typography gutterBottom>
              Surface ({surfaceRange[0]} - {surfaceRange[1]} m²)
            </Typography>
            <Slider
              value={surfaceRange}
              onChange={(e, newValue) => setSurfaceRange(newValue)}
              valueLabelDisplay="auto"
              min={0}
              max={200}
              step={5}
              valueLabelFormat={(value) => `${value} m²`}
            />
          </Grid>
        </Grid>
      </Paper>
      
      {/* Résultats et tri */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h6">
          {filteredProperties.length} résultats trouvés
        </Typography>
        
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="body1" sx={{ mr: 2 }}>
            Trier par:
          </Typography>
          <FormControl sx={{ minWidth: 200 }}>
            <Select
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              displayEmpty
              size="small"
            >
              <MenuItem value="date">Date (plus récentes)</MenuItem>
              <MenuItem value="price_asc">Prix (croissant)</MenuItem>
              <MenuItem value="price_desc">Prix (décroissant)</MenuItem>
              <MenuItem value="surface_asc">Surface (croissante)</MenuItem>
              <MenuItem value="surface_desc">Surface (décroissante)</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
      
      {/* Liste des propriétés */}
      <Grid container spacing={3}>
        {displayedProperties.map((property) => (
          <Grid item key={property.id} xs={12} sm={6} md={4}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', position: 'relative' }}>
              <Box sx={{ position: 'absolute', top: 10, right: 10, zIndex: 1 }}>
                <IconButton 
                  sx={{ 
                    bgcolor: 'rgba(255, 255, 255, 0.8)',
                    '&:hover': { bgcolor: 'rgba(255, 255, 255, 0.9)' }
                  }}
                >
                  <FavoriteBorderIcon />
                </IconButton>
              </Box>
              
              <Box sx={{ position: 'absolute', top: 10, left: 10, zIndex: 1 }}>
                <Chip 
                  label={property.source} 
                  size="small" 
                  sx={{ 
                    bgcolor: 'rgba(255, 255, 255, 0.8)',
                    fontWeight: 'bold'
                  }} 
                />
              </Box>
              
              <CardMedia
                component="img"
                height="200"
                image={property.imageUrl}
                alt={property.title}
              />
              
              <CardContent sx={{ flexGrow: 1 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 1 }}>
                  <Typography variant="h6" component="h2" gutterBottom noWrap>
                    {property.title}
                  </Typography>
                  <Typography variant="h6" color="primary" fontWeight="bold">
                    {formatPrice(property.price)}
                  </Typography>
                </Box>
                
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <LocationOnIcon fontSize="small" color="action" sx={{ mr: 0.5 }} />
                  <Typography variant="body2" color="text.secondary">
                    {property.location}, {property.postalCode}
                  </Typography>
                </Box>
                
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mb: 2 }}>
                  <Chip 
                    icon={<HomeIcon />} 
                    label={property.propertyType} 
                    size="small" 
                    variant="outlined" 
                  />
                  <Chip 
                    icon={<SquareFootIcon />} 
                    label={`${property.surface} m²`} 
                    size="small" 
                    variant="outlined" 
                  />
                  <Chip 
                    icon={<BedIcon />} 
                    label={`${property.rooms} pièce${property.rooms > 1 ? 's' : ''}`} 
                    size="small" 
                    variant="outlined" 
                  />
                </Box>
                
                <Typography variant="body2" color="text.secondary" sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                }}>
                  {property.description}
                </Typography>
              </CardContent>
              
              <CardActions sx={{ justifyContent: 'space-between', px: 2, pb: 2 }}>
                <Button 
                  size="small" 
                  startIcon={<NotificationsIcon />}
                  component={RouterLink}
                  to={`/creer-alerte?type=${property.propertyType}&location=${property.location}&price=${property.price}&surface=${property.surface}&rooms=${property.rooms}`}
                >
                  Créer alerte
                </Button>
                <Button 
                  variant="contained" 
                  size="small"
                  component={RouterLink}
                  to={`/propriete/${property.id}`}
                >
                  Voir détails
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      
      {/* Pagination */}
      {filteredProperties.length > 0 && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <Pagination 
            count={Math.ceil(filteredProperties.length / propertiesPerPage)} 
            page={page} 
            onChange={handlePageChange} 
            color="primary" 
            size="large"
          />
        </Box>
      )}
      
      {/* Message si aucun résultat */}
      {filteredProperties.length === 0 && (
        <Box sx={{ textAlign: 'center', py: 8 }}>
          <Typography variant="h5" gutterBottom>
            Aucun résultat ne correspond à vos critères
          </Typography>
          <Typography variant="body1" color="text.secondary" paragraph>
            Essayez de modifier vos filtres pour obtenir plus de résultats
          </Typography>
          <Button 
            variant="contained" 
            onClick={() => {
              setTransactionType('Vente');
              setPropertyType('');
              setLocation('');
              setPriceRange([0, 1000000]);
              setSurfaceRange([0, 200]);
              setRooms('');
            }}
          >
            Réinitialiser les filtres
          </Button>
        </Box>
      )}
    </Container>
  );
};

export default SearchPage;
