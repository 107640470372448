import React from 'react';
import { Container, Typography, Box, Button, Grid, Card, CardContent, CardMedia, CardActions } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import DevicesIcon from '@mui/icons-material/Devices';

const HomePage = () => {
  return (
    <Box>
      {/* Section Hero */}
      <Box 
        sx={{ 
          bgcolor: 'primary.main', 
          color: 'white', 
          py: 8,
          backgroundImage: 'linear-gradient(rgba(66, 133, 244, 0.85), rgba(66, 133, 244, 0.95)), url(https://source.unsplash.com/random/?realestate)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={7}>
              <Typography variant="h2" component="h1" gutterBottom fontWeight="bold">
                Trouvez votre bien immobilier idéal
              </Typography>
              <Typography variant="h5" paragraph sx={{ mb: 4 }}>
                ImmoScraper agrège les annonces de plusieurs plateformes immobilières et vous alerte en temps réel des nouvelles opportunités.
              </Typography>
              <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                <Button 
                  variant="contained" 
                  size="large" 
                  component={RouterLink} 
                  to="/recherche"
                  sx={{ 
                    bgcolor: 'secondary.main',
                    '&:hover': {
                      bgcolor: 'secondary.dark',
                    }
                  }}
                >
                  Rechercher maintenant
                </Button>
                <Button 
                  variant="outlined" 
                  size="large" 
                  component={RouterLink} 
                  to="/creer-alerte"
                  sx={{ 
                    color: 'white',
                    borderColor: 'white',
                    '&:hover': {
                      borderColor: 'white',
                      bgcolor: 'rgba(255, 255, 255, 0.1)',
                    }
                  }}
                >
                  Créer une alerte
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} md={5} sx={{ display: { xs: 'none', md: 'block' } }}>
              {/* Placeholder pour une image ou une illustration */}
              <Box 
                sx={{ 
                  bgcolor: 'rgba(255, 255, 255, 0.2)',
                  borderRadius: 4,
                  p: 2,
                  height: 300,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Typography variant="h4">
                  Visualisation des annonces
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Section Fonctionnalités */}
      <Container maxWidth="lg" sx={{ py: 8 }}>
        <Typography variant="h3" component="h2" align="center" gutterBottom>
          Comment ça fonctionne
        </Typography>
        <Typography variant="h6" align="center" color="text.secondary" paragraph sx={{ mb: 6 }}>
          PremsImmo simplifie votre recherche immobilière grâce à ces fonctionnalités
        </Typography>

        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>
                <SearchIcon sx={{ fontSize: 80, color: 'primary.main' }} />
              </Box>
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography gutterBottom variant="h5" component="h3" align="center">
                  Recherche multi-plateformes
                </Typography>
                <Typography align="center">
                  Accédez aux annonces de 7 plateformes immobilières en une seule recherche, avec des filtres personnalisés selon vos critères.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>
                <NotificationsActiveIcon sx={{ fontSize: 80, color: 'primary.main' }} />
              </Box>
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography gutterBottom variant="h5" component="h3" align="center">
                  Alertes en temps réel
                </Typography>
                <Typography align="center">
                  Recevez des notifications dès qu'une nouvelle annonce correspondant à vos critères est publiée, toutes les 5 minutes.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>
                <DevicesIcon sx={{ fontSize: 80, color: 'primary.main' }} />
              </Box>
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography gutterBottom variant="h5" component="h3" align="center">
                  Interface responsive
                </Typography>
                <Typography align="center">
                  Utilisez PremsImmo sur tous vos appareils : ordinateur, tablette ou smartphone, avec une expérience optimisée.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>

      {/* Section Plateformes */}
      <Box sx={{ bgcolor: 'grey.100', py: 8 }}>
        <Container maxWidth="lg">
          <Typography variant="h3" component="h2" align="center" gutterBottom>
            Plateformes immobilières
          </Typography>
          <Typography variant="h6" align="center" color="text.secondary" paragraph sx={{ mb: 6 }}>
            PremsImmo agrège les annonces des plateformes suivantes
          </Typography>

          <Grid container spacing={2} justifyContent="center">
            {['BienIci', 'ParuVendu', 'LeSiteImmo', 'EtreProprio', 'Immonot', 'ImmoRegion', 'OuestImmo'].map((platform) => (
              <Grid item key={platform} xs={6} sm={4} md={3} lg={2}>
                <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2 }}>
                  <Typography variant="h6" component="h3" align="center">
                    {platform}
                  </Typography>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* Section CTA */}
      <Container maxWidth="md" sx={{ py: 8, textAlign: 'center' }}>
        <Typography variant="h3" component="h2" gutterBottom>
          Prêt à trouver votre bien idéal ?
        </Typography>
        <Typography variant="h6" color="text.secondary" paragraph sx={{ mb: 4 }}>
          Créez votre compte gratuitement et configurez vos premières alertes immobilières avec PremsImmo
        </Typography>
        <Button 
          variant="contained" 
          size="large" 
          component={RouterLink} 
          to="/inscription"
          sx={{ px: 4, py: 1.5 }}
        >
          S'inscrire gratuitement
        </Button>
      </Container>
    </Box>
  );
};

export default HomePage;
