import React from 'react';
import { Box, Container, Typography, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: 'auto',
        backgroundColor: (theme) => theme.palette.grey[100]
      }}
    >
      <Container maxWidth="lg">
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'space-between', alignItems: { xs: 'center', md: 'flex-start' } }}>
          <Box sx={{ mb: { xs: 2, md: 0 }, textAlign: { xs: 'center', md: 'left' } }}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              PremsImmo
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Moteur de recherche d'annonces immobilières avec alertes en temps réel
            </Typography>
          </Box>
          
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: { xs: 2, sm: 4 } }}>
            <Box>
              <Typography variant="subtitle1" color="text.primary" gutterBottom>
                Navigation
              </Typography>
              <Link component={RouterLink} to="/" color="inherit" display="block" sx={{ mb: 0.5 }}>
                Accueil
              </Link>
              <Link component={RouterLink} to="/recherche" color="inherit" display="block" sx={{ mb: 0.5 }}>
                Recherche
              </Link>
              <Link component={RouterLink} to="/alertes" color="inherit" display="block" sx={{ mb: 0.5 }}>
                Alertes
              </Link>
            </Box>
            
            <Box>
              <Typography variant="subtitle1" color="text.primary" gutterBottom>
                Compte
              </Typography>
              <Link component={RouterLink} to="/connexion" color="inherit" display="block" sx={{ mb: 0.5 }}>
                Connexion
              </Link>
              <Link component={RouterLink} to="/inscription" color="inherit" display="block" sx={{ mb: 0.5 }}>
                Inscription
              </Link>
            </Box>
            
            <Box>
              <Typography variant="subtitle1" color="text.primary" gutterBottom>
                Informations
              </Typography>
              <Link component={RouterLink} to="/a-propos" color="inherit" display="block" sx={{ mb: 0.5 }}>
                À propos
              </Link>
              <Link component={RouterLink} to="/confidentialite" color="inherit" display="block" sx={{ mb: 0.5 }}>
                Confidentialité
              </Link>
              <Link component={RouterLink} to="/contact" color="inherit" display="block" sx={{ mb: 0.5 }}>
                Contact
              </Link>
            </Box>
          </Box>
        </Box>
        
        <Box sx={{ mt: 3, textAlign: 'center' }}>
          <Typography variant="body2" color="text.secondary">
            © {new Date().getFullYear()} PremsImmo. Tous droits réservés.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
