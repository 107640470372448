import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Typography, 
  Box, 
  Grid, 
  Card, 
  CardContent, 
  CardActions,
  Button,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Chip,
  Paper,
  Switch,
  FormControlLabel,
  Divider,
  Alert,
  Snackbar
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

// Données simulées pour la démonstration
const mockAlerts = [
  {
    id: 1,
    name: "Appartement à Paris",
    criteria: {
      transactionType: "Vente",
      propertyType: "Appartement",
      location: "Paris",
      minPrice: 200000,
      maxPrice: 500000,
      minSurface: 40,
      maxSurface: 80,
      minRooms: 2
    },
    frequency: "real-time",
    active: true,
    createdAt: "2025-03-15T10:30:00Z",
    lastTriggeredAt: "2025-04-09T14:22:00Z",
    matchCount: 12
  },
  {
    id: 2,
    name: "Maison en périphérie de Lyon",
    criteria: {
      transactionType: "Vente",
      propertyType: "Maison",
      location: "Lyon",
      minPrice: 300000,
      maxPrice: 700000,
      minSurface: 100,
      maxSurface: 200,
      minRooms: 4
    },
    frequency: "daily",
    active: true,
    createdAt: "2025-03-20T15:45:00Z",
    lastTriggeredAt: "2025-04-10T08:00:00Z",
    matchCount: 5
  },
  {
    id: 3,
    name: "Studio à louer à Bordeaux",
    criteria: {
      transactionType: "Location",
      propertyType: "Studio",
      location: "Bordeaux",
      minPrice: 400,
      maxPrice: 700,
      minSurface: 20,
      maxSurface: 40,
      minRooms: 1
    },
    frequency: "real-time",
    active: false,
    createdAt: "2025-04-01T09:15:00Z",
    lastTriggeredAt: "2025-04-05T11:30:00Z",
    matchCount: 3
  }
];

const AlertsPage = () => {
  const [alerts, setAlerts] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  // Simuler le chargement des alertes
  useEffect(() => {
    // Dans une application réelle, cela serait un appel API
    setAlerts(mockAlerts);
  }, []);

  // Gestionnaire pour activer/désactiver une alerte
  const handleToggleActive = (alertId) => {
    setAlerts(alerts.map(alert => 
      alert.id === alertId ? { ...alert, active: !alert.active } : alert
    ));
    
    const alert = alerts.find(a => a.id === alertId);
    const newStatus = !alert.active;
    
    setSnackbarMessage(`Alerte "${alert.name}" ${newStatus ? 'activée' : 'désactivée'}`);
    setSnackbarOpen(true);
  };

  // Gestionnaire pour supprimer une alerte
  const handleDeleteAlert = (alertId) => {
    const alertToDelete = alerts.find(a => a.id === alertId);
    setAlerts(alerts.filter(alert => alert.id !== alertId));
    
    setSnackbarMessage(`Alerte "${alertToDelete.name}" supprimée`);
    setSnackbarOpen(true);
  };

  // Formatage de la date
  const formatDate = (dateString) => {
    const options = { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    };
    return new Date(dateString).toLocaleDateString('fr-FR', options);
  };

  // Formatage du prix
  const formatPrice = (price) => {
    return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(price);
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
        <Typography variant="h4" component="h1">
          Mes alertes immobilières
        </Typography>
        
        <Button 
          variant="contained" 
          color="primary" 
          startIcon={<AddIcon />}
          component={RouterLink}
          to="/creer-alerte"
        >
          Créer une alerte
        </Button>
      </Box>
      
      {alerts.length === 0 ? (
        <Paper sx={{ p: 4, textAlign: 'center' }}>
          <NotificationsActiveIcon sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
          <Typography variant="h5" gutterBottom>
            Vous n'avez pas encore d'alertes
          </Typography>
          <Typography variant="body1" color="text.secondary" paragraph>
            Créez votre première alerte pour être notifié des nouvelles annonces correspondant à vos critères
          </Typography>
          <Button 
            variant="contained" 
            startIcon={<AddIcon />}
            component={RouterLink}
            to="/creer-alerte"
          >
            Créer ma première alerte
          </Button>
        </Paper>
      ) : (
        <Grid container spacing={3}>
          {alerts.map((alert) => (
            <Grid item key={alert.id} xs={12}>
              <Card>
                <CardContent>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 2 }}>
                    <Box>
                      <Typography variant="h5" component="h2" gutterBottom>
                        {alert.name}
                      </Typography>
                      <Box sx={{ display: 'flex', gap: 1, mb: 1 }}>
                        <Chip 
                          label={alert.criteria.transactionType} 
                          color="primary" 
                          size="small" 
                          variant="outlined" 
                        />
                        <Chip 
                          label={alert.criteria.propertyType} 
                          color="primary" 
                          size="small" 
                          variant="outlined" 
                        />
                        <Chip 
                          label={alert.criteria.location} 
                          color="primary" 
                          size="small" 
                          variant="outlined" 
                        />
                        <Chip 
                          label={`${alert.matchCount} correspondance${alert.matchCount > 1 ? 's' : ''}`} 
                          color="secondary" 
                          size="small" 
                        />
                      </Box>
                    </Box>
                    
                    <FormControlLabel
                      control={
                        <Switch 
                          checked={alert.active} 
                          onChange={() => handleToggleActive(alert.id)}
                          color="primary"
                        />
                      }
                      label={alert.active ? "Activée" : "Désactivée"}
                    />
                  </Box>
                  
                  <Divider sx={{ my: 2 }} />
                  
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                        Prix
                      </Typography>
                      <Typography variant="body1">
                        {formatPrice(alert.criteria.minPrice)} - {formatPrice(alert.criteria.maxPrice)}
                      </Typography>
                    </Grid>
                    
                    <Grid item xs={12} md={4}>
                      <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                        Surface
                      </Typography>
                      <Typography variant="body1">
                        {alert.criteria.minSurface} - {alert.criteria.maxSurface} m²
                      </Typography>
                    </Grid>
                    
                    <Grid item xs={12} md={4}>
                      <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                        Pièces (minimum)
                      </Typography>
                      <Typography variant="body1">
                        {alert.criteria.minRooms} pièce{alert.criteria.minRooms > 1 ? 's' : ''}
                      </Typography>
                    </Grid>
                  </Grid>
                  
                  <Divider sx={{ my: 2 }} />
                  
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                        Fréquence
                      </Typography>
                      <Typography variant="body1">
                        {alert.frequency === 'real-time' ? 'Temps réel' : 'Quotidienne'}
                      </Typography>
                    </Grid>
                    
                    <Grid item xs={12} md={4}>
                      <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                        Créée le
                      </Typography>
                      <Typography variant="body1">
                        {formatDate(alert.createdAt)}
                      </Typography>
                    </Grid>
                    
                    <Grid item xs={12} md={4}>
                      <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                        Dernière notification
                      </Typography>
                      <Typography variant="body1">
                        {alert.lastTriggeredAt ? formatDate(alert.lastTriggeredAt) : 'Jamais'}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
                
                <CardActions sx={{ justifyContent: 'flex-end', p: 2 }}>
                  <Button 
                    startIcon={<EditIcon />}
                    component={RouterLink}
                    to={`/creer-alerte?edit=${alert.id}`}
                  >
                    Modifier
                  </Button>
                  <Button 
                    startIcon={<DeleteIcon />}
                    color="error"
                    onClick={() => handleDeleteAlert(alert.id)}
                  >
                    Supprimer
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
      
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={() => setSnackbarOpen(false)} 
          severity="success" 
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AlertsPage;
